.card {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url('../../images/card-corners.svg');
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.img {
  width: 100%;
}

.date {
  border: 1px solid #4f4b5c;
  font-family: IQOS;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-align: center;
  color: #ffffff;
  padding: 6px 10px;
  position: absolute;
  top: 10px;
  left: 10px;
  border-radius: 90px;
}

.text {
  font-family: IQOS;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-align: center;
  position: absolute;
  bottom: 11px;
  max-width: 80%;
  color: #519096;
}
.text.blue {
  color: #87fbfc;
  text-shadow: 1px 1px 2px #00d1d299;
}

.text.yellow {
  color: #fcd487;
}

.text.tomorrow {
  color: #519096;
}

.text.missed {
  color: #857e8e;
}

.button {
  padding: 7px 35px;
  position: absolute;
  bottom: 11px;
  border: none;
  font-family: IQOS;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  color: #34303d;
  background: linear-gradient(94.04deg, #ffffff 0%, #b5ffff 100%);
  border-radius: 27px;
}
