.authCode {
  background-color: #34303d;
  min-height: 100vh;
}

.content {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.title {
  font-family: IQOS;
  font-size: 30px;
  font-weight: 700;
  line-height: 32px;
  text-align: left;
  color: #ffffff;
  margin-bottom: 20px;
}

.subtitle {
  font-family: IQOS;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #ffffff;
  margin-bottom: 8px;
}

.link {
  color: #00d1d2;
}

.spam {
  color: #857e8e;
  margin-bottom: 40px;
}

.label {
  font-family: IQOS;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #ffffff;
  display: flex;
  flex-direction: column;
}

.input {
  font-family: IQOS;
  font-size: 30px;
  font-weight: 700;
  line-height: 32px;
  text-align: left;
  letter-spacing: 12px;
  color: #ffffff;
  border: none;
  background-color: transparent;
  padding: 12px 0;
  border-bottom: 1px solid #4f4b5c;
}

input.error {
  border-bottom: 1px solid #ff3347;
}

.input::placeholder {
  color: #857e8e;
}

.input:focus-visible {
  outline: none;
}

.errorText {
  font-family: IQOS;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #ff3347;
  margin-top: 8px;
}

.button {
  width: 100%;
  border-radius: 90px;
  padding: 16px 32px;
  background-color: #ffffff;
  font-family: IQOS;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  color: #34303d;
  border: none;
  flex-direction: column;
  margin-top: 40px;
}

.button:disabled {
  background-color: #857e8e;
  color: #d1ced8;
}
