.final {
  background-color: #34303d;
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
}

.title {
  font-family: IQOS;
  font-size: 36px;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #ffffff;
  padding: 20px;
}

.subtitle {
  font-family: IQOS;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #ffffff;
  padding: 0 20px;
  margin-bottom: 50px;
  flex-grow: 1;
}

.img {
  width: 100%;
}
