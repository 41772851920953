.question {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(52, 48, 61, 0.9);
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.container {
  padding: 0 20px;
  background-color: #ffffff;
  width: 100%;
  height: 95%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  overflow: auto;
  position: relative;
}

.closeButton {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-image: url(../../images/button-cross.svg);
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  position: absolute;
  right: 12px;
  top: 12px;
  z-index: 2;
}

.img {
  width: 100%;
}

.closeButton:hover {
  opacity: 0.8;
  cursor: pointer;
}

.title {
  font-family: IQOS;
  font-size: 22px;
  font-weight: 700;
  line-height: 26px;
  text-align: center;
  color: #34303d;
  margin-bottom: 12px;
}

.subtitle {
  font-family: IQOS;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  color: #857e8e;
  margin-bottom: 20px;
}

.answerList {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 20px;
}

.button {
  font-family: IQOS;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #ffffff;
  background-color: #34303d;
  width: 100%;
  padding: 16px 0;
  text-align: center;
  border-radius: 90px;
  border: none;
  margin-bottom: 20px;
}

.button:disabled {
  opacity: 0.8;
}

.answerContainer {
  background-color: #00d1d2;
  width: 100%;
  height: 95%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding-top: 290px;
  position: relative;
  overflow: visible;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.answerContainer.gold {
  background: linear-gradient(148.54deg, #f6e0b6 0%, #b76a10 100%);
}

.content {
  padding: 0 20px;
  width: 100%;
  overflow: scroll;
}

.answerImg {
  position: absolute;
  top: -5vw;
  left: 0;
  width: 100%;
}

.answerTitle {
  font-family: IQOS;
  font-size: 30px;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
  margin-bottom: 16px;
}

.answerSubtitle {
  font-family: IQOS;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
  margin-bottom: 16px;
}

.promocodeText {
  font-family: IQOS;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #b7ffff;
  margin-bottom: 24px;
}

.promocodeText.gold {
  color: #fff2da;
}

.promocodeContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 33px;
  padding: 16px 17px 16px 20px;
  background-color: #ffffff;
  border-radius: 16px;
  width: 80%;
  margin: 0 auto;
  margin-bottom: 24px;
}

.promocode {
  font-family: IQOS;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #34303d;
}

.copyButton {
  background-image: url(../../images/copy.svg);
  width: 17px;
  height: 20px;
  border: none;
  background-color: transparent;
}

.text {
  font-family: IQOS;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #b7ffff;
  margin-bottom: 24px;
}

.link {
  color: #ffffff;
  text-decoration: underline;
}

.buttonLoader {
  height: 20px;
}
