.nav {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  border-bottom: 1px solid #4f4b5c;
}

.backButton {
  background-image: url(../../images/back-icon.svg);
  background-repeat: no-repeat;
  border: none;
  background-color: transparent;
  width: 11px;
  height: 20px;
}

.logo {
  flex-grow: 1;
  width: 123px;
  height: 20px;
}
