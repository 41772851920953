.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(52, 48, 61, 0.9);

  z-index: 3;
  padding-bottom: 42px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.modal {
  margin: 0 auto;
  width: 95%;
  background-color: #ffffff;
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 16px;
}

.img {
  width: 50px;
  height: 50px;
}
.title {
  font-family: IQOS;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #34303d;
  margin-bottom: 20px;
}

.text {
  font-family: IQOS;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #949494;
  margin-bottom: 32px;
}

.button {
  width: 100%;
  background-color: #34303d;
  border-radius: 90px;
  padding: 16px 0;
  font-family: IQOS;
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
  border: none;
  text-align: center;
}

.closeButton {
  border: none;
  width: 24px;
  height: 24px;
  background-color: rgba(52, 48, 61, 0.12);
  border-radius: 50%;
  background-image: url(../../images/button-cross.svg);
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 16px;
  right: 16px;
}
