.main {
  background-color: #34303d;
  min-height: 100vh;
}

.ribbon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  /* height: fit-content; */
}

.content {
  padding: 20px;
  z-index: 1;
  position: relative;
}

.title {
  font-family: IQOS;
  font-size: 36px;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #ffffff;
  margin-bottom: 20px;
}

.subtitle {
  font-family: IQOS;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #ffffff;
  margin-bottom: 20px;
}

.link {
  color: #00d1d2;
}

.countdown {
  font-family: IQOS;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  padding: 8px 12px;
  background-color: #00d1d2;
  border-radius: 27px;
  width: fit-content;
  margin-bottom: 80px;
}

.cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 17px 3%;
  grid-template-areas:
    '. .'
    '. .';
}
