.authActual {
  background-color: #34303d;
  min-height: 100vh;
}

.content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.img {
  margin-bottom: 20px;
  width: 64px;
  height: 64px;
}

.title {
  font-family: IQOS;
  font-size: 30px;
  font-weight: 700;
  line-height: 32px;
  text-align: left;
  color: #ffffff;
  margin-bottom: 20px;
}

.subtitle {
  font-family: IQOS;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #ffffff;
  margin-bottom: 48px;
}

.primeButton {
  width: 100%;
  border-radius: 90px;
  padding: 16px 32px;
  background-color: #ffffff;
  font-family: IQOS;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  color: #34303d;
  border: none;
  margin-bottom: 16px;
}

.secondaryButton {
  width: 100%;
  border-radius: 90px;
  padding: 16px 32px;
  background-color: transparent;
  font-family: IQOS;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  border: 1px solid #4f4b5c;
}
