.overlay {
  background-color: rgba(52, 48, 61, 0.9);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.modal {
  height: 80%;
  background-color: #ffffff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.closeButton {
  position: absolute;
  top: 12px;
  right: 12px;
  width: 40px;
  height: 40px;
  background-color: #efeef2;
  border: none;
  border-radius: 50%;
  background-image: url(../../images/button-cross.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.img {
  width: 70%;
  margin-bottom: 32px;
}

.title {
  font-family: IQOS;
  font-size: 22px;
  font-weight: 700;
  line-height: 26px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #34303d;
  margin-bottom: 16px;
}

.text {
  font-family: IQOS;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #857e8e;
  margin-bottom: 20px;
}

.button {
  font-family: IQOS;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #34303d;
  width: 100%;
  padding: 16px 0;
  border-radius: 90px;
  border: none;
}
