@font-face {
  font-family: 'IQOS';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./IQOS-Regular.ttf');
}

@font-face {
  font-family: 'IQOS';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('./IQOS-Bold.ttf');
}

@font-face {
  font-family: 'IQOS';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('./IQOS-Light.ttf');
}
