.authSuccess {
  background-color: #34303d;
  min-height: 100vh;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 80px 20px 0 20px;
}

.img {
  margin-bottom: 20px;
  width: 64px;
  height: 64px;
}

.title {
  font-family: IQOS;
  font-size: 30px;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 20px;
}

.subtitle {
  font-family: IQOS;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #ffffff;
  margin-bottom: 48px;
}

.button {
  width: 100%;
  border-radius: 90px;
  padding: 16px 32px;
  background-color: #ffffff;
  font-family: IQOS;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  color: #34303d;
  border: none;
}
