.button {
  font-family: IQOS;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #34303d;
  text-align: center;
  border: 1px solid #efeef2;
  width: 100%;
  padding: 12px 0;
  background-color: transparent;
  border-radius: 16px;
  text-align: center;
}

.button.checked {
  background-color: #efeef2;
}

.input {
  position: absolute;
  opacity: 0;
}
