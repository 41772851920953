.modal {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(52, 48, 61, 0.9);
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.closeButton {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-image: url(../../images/button-cross.svg);
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  position: absolute;
  right: 12px;
  top: 12px;
  z-index: 2;
}

.img {
  width: 100%;
}

.closeButton:hover {
  opacity: 0.8;
  cursor: pointer;
}

.container {
  background-color: #00d1d2;
  width: 100%;
  height: 85%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding-top: 300px;
  position: relative;
  overflow: visible;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content {
  width: 100%;
  overflow: scroll;
}

.img {
  position: absolute;
  top: -5vw;
  left: 0;
  width: 100%;
}

.title {
  font-family: IQOS;
  font-size: 30px;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
  margin-bottom: 16px;
  padding: 0 20px;
}

.subtitle {
  font-family: IQOS;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
  margin-bottom: 16px;
  padding: 0 20px;
}

.text {
  font-family: IQOS;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #b7ffff;
  margin-bottom: 24px;
}

.miniModal {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 24px 20px 40px 24px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.modalImg {
  width: 120px;
  height: 94px;
}

.miniText {
  font-family: IQOS;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #34303d;
}
