.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(52, 48, 61, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
}

.img {
  animation: 3s linear infinite rotate;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
